import React from "react";
import "../Introduction.css";

const Introduction = () => {
  return (
    <div className="introduction">
      <img 
        src="https://andrewslearningimage-bucket.s3.amazonaws.com/media/Aboutus_image.jpeg" 
        alt="Andrew Browne" 
        className="profile-photo" 
      />
      <div className="intro-text">
        <center>
          <h1>Welcome!</h1>
          <p>
            Hi! I’m Andrew Browne, a biochemist specializing in assay development, structural 
            biology, and computational chemistry. I am also a trained full stack developer 
            and have experience with various aspects in machine learning! I build custom 
            web applications and tools for a variety of industries. Explore my portfolio 
            to see my work, and feel free to reach out if you’d like to collaborate!
          </p>
          <div className="links">
            <p>
              {/* GitHub link */}
              <a
                href="https://github.com/andrewbrowne3"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
              {" | "}
              
              {/* Email link (opens email client) */}
              <a href="mailto:andrewbrowne161@gmail.com">
                andrewbrowne161@gmail.com
              </a>
              {" | "}

              {/* LinkedIn link */}
              <a
                href="https://www.linkedin.com/in/andrew-browne-11a08a13b/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </p>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Introduction;
